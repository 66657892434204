body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-track {
  display: flex !important;
  margin-bottom: 20px;
}

.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
  align-items: stretch;
}

.slick-slide.slick-center {
  transform: scale(1.09);
  transition: all 0.5s ease-in-out;
}

.slick-dots li button::before {
  font-size: 1rem !important;
  color: #fff !important;
  opacity: 0.7;
}

.slick-dots li.slick-active button::before {
  color: #fff !important;
  opacity: 1;
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
